import type { RouteRecordRaw } from 'vue-router'

import { RouteNames as SharedRouteNames } from '@/shared/routes'

import { Section } from './shared/constants'

const Blank = () => import('@/pages/Blank.vue')
const StoredReportJobList = () => import('@/pages/Reports/StoredReportJobs/List.vue')
const StoredReportRecipientList = () => import('@/pages/Reports/StoredReportRecipients/List.vue')
const StoredReportScheduleList = () => import('@/pages/Reports/StoredReportSchedules/List.vue')
const StoredReportContainer = () => import('@/pages/Reports/StoredReports/Container.vue')
const CreateStoredReport = () => import('@/pages/Reports/StoredReports/Create.vue')
const EditStoredReport = () => import('@/pages/Reports/StoredReports/Edit.vue')
const StoredReportList = () => import('@/pages/Reports/StoredReports/List.vue')

export const routeNames = {
  REPORTS_STORED_REPORTS_LIST: 'REPORTS_STORED_REPORTS_LIST',
  REPORTS_STORED_REPORTS_EDIT: 'REPORTS_STORED_REPORTS_EDIT',
  REPORTS_STORED_REPORTS_EDIT_REDIRECT: 'REPORTS_STORED_REPORTS_EDIT_REDIRECT',
  REPORTS_STORED_REPORTS_CREATE: 'REPORTS_STORED_REPORTS_CREATE',
  REPORTS_STORED_REPORTS_RECIPIENTS: 'REPORTS_STORED_REPORTS_RECIPIENTS',
  REPORTS_STORED_REPORTS_SCHEDULES: 'REPORTS_STORED_REPORTS_SCHEDULES',
  REPORTS_STORED_REPORTS_JOBS: 'REPORTS_STORED_REPORTS_JOBS',

  REPORTS_PRODUCTS: 'REPORTS_PRODUCTS',
  REPORTS_PRODUCT_GROUPS: 'REPORTS_PRODUCT_GROUPS',
  REPORTS_RETURN_ITEMS: 'REPORTS_RETURN_ITEMS',
  REPORTS_WAITERS: 'REPORTS_WAITERS',
  REPORTS_WEIGHT: 'REPORTS_WEIGHT',
  REPORTS_FINANCIAL_REPORT: 'REPORTS_MONTHLY_REPORT',
  REPORTS_FINANCIAL_REPORT_SALES: 'REPORTS_MONTHLY_REPORT_SALES',
  REPORTS_FINANCIAL_REPORT_VAT_RATES: 'REPORTS_MONTHLY_REPORT_VAT_RATES',
  REPORTS_FINANCIAL_REPORT_FEES: 'REPORTS_MONTHLY_REPORT_FEES',
  REPORTS_BUNDLES: 'REPORTS_BUNDLES',
  REPORTS_MODIFIERS: 'REPORTS_MODIFIERS',
  REPORTS_FINANCE_ACCOUNTS: 'REPORTS_FINANCE_ACCOUNTS',
  REPORTS_PAYOUTS: 'REPORTS_PAYOUTS',
  REPORTS_GENERATED_REPORTS: 'REPORTS_GENERATED_REPORTS',
}

const routes: RouteRecordRaw[] = [
  {
    path: 'stored-reports',
    component: Blank,
    meta: {
      section: Section.REPORTS,
    },
    children: [
      {
        path: '',
        name: routeNames.REPORTS_STORED_REPORTS_LIST,
        component: StoredReportList,
      },
      {
        path: 'create',
        name: routeNames.REPORTS_STORED_REPORTS_CREATE,
        component: CreateStoredReport,
      },
      {
        path: ':storedReportId',
        component: StoredReportContainer,
        props: true,
        children: [
          {
            path: '',
            name: routeNames.REPORTS_STORED_REPORTS_EDIT,
            component: EditStoredReport,
            meta: { subsection: 'edit' },
          },
          {
            path: 'edit',
            name: routeNames.REPORTS_STORED_REPORTS_EDIT_REDIRECT,
            redirect: () => ({ name: routeNames.REPORTS_STORED_REPORTS_EDIT }),
          },
          {
            path: 'stored-report-recipients',
            name: routeNames.REPORTS_STORED_REPORTS_RECIPIENTS,
            component: StoredReportRecipientList,
            meta: { subsection: 'stored-report-recipients' },
          },
          {
            path: 'stored-report-schedules',
            name: routeNames.REPORTS_STORED_REPORTS_SCHEDULES,
            component: StoredReportScheduleList,
            meta: { subsection: 'stored-report-schedules' },
          },
          {
            path: 'stored-report-jobs',
            name: routeNames.REPORTS_STORED_REPORTS_JOBS,
            component: StoredReportJobList,
            meta: { subsection: 'stored-report-jobs' },
          },
        ],
      },
    ],
  },
  {
    path: 'reports',
    component: () => import('@/pages/Blank.vue'),
    meta: {
      section: Section.REPORTS,
    },
    children: [
      {
        path: 'products',
        name: routeNames.REPORTS_PRODUCTS,
        component: () => import('@/pages/Reports/Products/ProductsReport.vue'),
      },
      {
        path: 'product-groups',
        name: routeNames.REPORTS_PRODUCT_GROUPS,
        component: () => import('@/pages/Reports/ProductGroups/ProductGroupsReport.vue'),
      },
      {
        path: 'return-items',
        name: routeNames.REPORTS_RETURN_ITEMS,
        component: () => import('@/pages/Reports/ReturnItems/ReturnItemsReport.vue'),
      },
      {
        path: 'staff',
        name: routeNames.REPORTS_WAITERS,
        component: () => import('@/pages/Reports/Waiters/WaitersReport.vue'),
      },
      {
        path: 'weight',
        name: routeNames.REPORTS_WEIGHT,
        component: () => import('@/pages/Reports/Weight/WeightReport.vue'),
      },
      {
        path: 'bundles',
        name: routeNames.REPORTS_BUNDLES,
        component: () => import('@/pages/Reports/Bundles/BundlesReport.vue'),
      },
      {
        path: 'modifiers',
        name: routeNames.REPORTS_MODIFIERS,
        component: () => import('@/pages/Reports/Modifiers/ModifiersReport.vue'),
      },
      {
        path: 'finance-accounts',
        name: routeNames.REPORTS_FINANCE_ACCOUNTS,
        component: () => import('@/pages/Reports/FinanceAccounts/FinanceAccountsReport.vue'),
      },
      {
        path: 'payouts/:year?/:month?',
        redirect: () => ({ name: SharedRouteNames.FINANCES_PAYOUTS }),
        name: routeNames.REPORTS_PAYOUTS,
      },
      {
        path: 'financial-report',
        component: () => import('@/pages/Reports/FinancialReport/FinancialReport.vue'),
        name: routeNames.REPORTS_FINANCIAL_REPORT,
        meta: {
          section: Section.REPORTS,
        },
        redirect: () => ({ name: routeNames.REPORTS_FINANCIAL_REPORT_SALES }),
        children: [
          {
            path: 'sales',
            name: routeNames.REPORTS_FINANCIAL_REPORT_SALES,
            component: () => import('@/pages/Reports/FinancialReport/FinancialReportSalesTab.vue'),
          },
          {
            path: 'vat-rates',
            name: routeNames.REPORTS_FINANCIAL_REPORT_VAT_RATES,
            component: () => import('@/pages/Reports/FinancialReport/FinancialReportVatRateTab.vue'),
          },
          {
            path: 'fees',
            name: routeNames.REPORTS_FINANCIAL_REPORT_FEES,
            component: () => import('@/pages/Reports/FinancialReport/FinancialReportFeesTab.vue'),
          },
        ],
      },
      {
        path: 'generated-reports',
        name: routeNames.REPORTS_GENERATED_REPORTS,
        component: () => import('@/pages/Reports/GeneratedReports/GeneratedReportsList.vue'),
      },
    ],
  },
]

export default routes
